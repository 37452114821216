
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCgwSlMoNB-zZqWTzFn3gqGed8O8vzKvVM",
    authDomain: "kijibizi-qrar.firebaseapp.com",
    projectId: "kijibizi-qrar",
    storageBucket: "kijibizi-qrar.appspot.com",
    messagingSenderId: "949370763616",
    appId: "1:949370763616:web:3ccc367f55c01c1d8f0605",
    measurementId: "G-LSP7WB4QBB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
